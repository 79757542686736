import React from "react";
import { graphql } from "gatsby";

import SEO from "../../components/seo";
import Layout from "../../components/layout";
import Form from "../../components/form";
import ContactBlock from "../../components/contact-info";
import styles from "../../scss/layouts/contact.module.scss";
import headerStyles from "../../scss/modules/text_header.module.scss"


export default ({ data }) => {
  const pageData = data.markdownRemark;
  const contactData = data.site.siteMetadata;
  const supportingImage = data.image.childImageSharp.fluid;

  return (
    <>
    <Layout>
    <SEO
        title={pageData.frontmatter.title}
        description={pageData.frontmatter.intro }
        pathname={pageData.fields.slug}
      />
        <div className={headerStyles.text_header__header_green}>
          <div className={headerStyles.text_header__header_inner}>
            <h1>{ pageData.frontmatter.title }</h1>
            <div dangerouslySetInnerHTML={{ __html: pageData.html }} />
          </div>
        </div>

        <div className={styles.contact}>
        <ContactBlock image={supportingImage}
                      kAddress={contactData.companyAddress}
                      kPhone={contactData.companyTelephoneKnaresborough}
                      email={contactData.companyEmail} />
        <div className={styles.contact__form}>
          <div className={styles.contact__form_inner}>
            <div className={styles.contact__form_fields}>
              <Form />
            </div>
          </div>
        </div>

      </div>
    </Layout>
    </>
  )
}



export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      frontmatter {
        title
        template
      }
      html
      fields {
        slug
      }
    }
    image:file(relativePath: {eq: "contact_us.jpg"}) {
      id
      childImageSharp {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    site {
      siteMetadata {
        companyTelephoneKnaresborough
        companyEmail
        companyAddress
      }
    }
  }
`
