import React from "react";
import styles from "../scss/layouts/contact.module.scss";
import Image from "gatsby-image";
import AddressIcon from "../images/svg/icon_location.inline.svg"
import EmailIcon from "../images/svg/icon_email.inline.svg"
import PhoneIcon from "../images/svg/icon_phone.inline.svg"

export default (props) => {
    return (
        <>
    <div className={styles.contact__details}>
        <div className={styles.contact__content}>
            <h3>Harrogate Office</h3>
            <div className={styles.contact__address}>
                <AddressIcon />
                <p>{props.kAddress}</p>
            </div>
            <div className={styles.contact__phone}>
                <PhoneIcon />
                <p><a href={"tel:"+props.kPhone} title={"Call "+props.kPhone}>{props.kPhone}</a></p>
            </div>
            <div className={styles.contact__email}>
                <EmailIcon />
                <p><a href={"mailto:"+props.email} title={"Email "+props.email}>{props.email}</a></p>
            </div>
        </div>
        <div className={styles.contact__image} alt={"Northern Planners | Yorkshire based design and consultancy"}>
            <Image fluid={props.image} />
        </div>
    </div>
    </>
  )
}