import React from 'react';
import axios from "axios"
import styles from "../scss/forms/contact-form.module.scss"
import cta from "../scss/elements/buttons.module.scss";

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      first_name: "",
      last_name: "",
      email_address: "",
      company: "",
      phone: "",
      enquiry: "",
      grandmas_rose_waved_mug: false,
      formErrors: {
        honeypot: "",
        first_name: "",
        last_name: "",
        email_address: "",
        enquiry: "",
      },
      formValid: null,
    }
  }
  handleInputChange = event => {
    const target = event.target
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name
    this.setState({
      [name]: value,
    }, this.validateField(name, value))
  }
  validateField = (name, value) => {
    let errors = this.state.formErrors

    switch(name) {
      case "first_name":
        errors.first_name =
          value.length <= 0
            ? 'Please enter your first name'
            : false;
        break;
      case "last_name":
        errors.last_name =
          value.length <= 0
            ? 'Please enter your last name'
            : false;
        break;
      case "email_address":
        errors.email_address =
          value.length <= 0
            ? 'Please enter your email address'
            : false;
        break;
      case "enquiry":
        errors.enquiry =
          value.length <= 0
            ? 'Please enter the reason for your enquiry'
            : false;
        break;
      default:
        break;
    }

    this.setState({errors, [name]: value}, ()=> {
      console.log(errors)
    })

    this.validateForm(this.state.formErrors)

  }
  validateForm = (errors) => {
    let valid = true;
    let fieldValues = this.state;

    Object.keys(errors).forEach(function (item) {
      if (fieldValues[item] === "") {
        valid = false;
      }
    });

    this.setState({formValid: valid})
    return valid;
  }
  handleSubmit = event => {
    event.preventDefault();



    if (this.validateForm(this.state.formErrors) && this.state.formValid === true) {

      axios.post('https://app.99inbound.com/api/e/_LPYVr02', {
        grandmas_rose_waved_mug: this.state.grandmas_rose_waved_mug,
        enquiry: this.state.enquiry,
        phone: this.state.phone,
        company: this.state.company,
        email_address: this.state.email_address,
        last_name: this.state.last_name,
        first_name: this.state.first_name,
      })
        .then(function (response) {
          if (response.status === 200) {
            window.location.href = "/contact/thank-you"
          }
        })
        .catch(function (error) {
          console.log(error);
          this.setState({submit_disabled: false});
        });
    } else {
      console.error('Invalid Form')
    }

  }
  render() {
    let validForm = this.state.formValid;
    let errorMessage;

    if (validForm === false) {
      errorMessage = <p>There are missing fields on the form below, please check the validation messages and try again</p>
    }

    return (
      <div id="contact_form">

        <h3>Get In Touch</h3>

        <form className={styles.contact_form} action="" method="POST" onSubmit={this.handleSubmit}>
          <div className={styles.contact_form__row}>
            <div className={styles.contact_form__row_half}>
              <label htmlFor="first_name" >First Name*</label>
              <input type="text" name="first_name" id="first_name" onBlur={this.handleInputChange} onChange={this.handleInputChange} />
              {this.state.formErrors.first_name.length > 0 &&
              <span className={styles.contact_form__mobile_error}>{this.state.formErrors.first_name}</span>}
            </div>
            <div className={styles.contact_form__row_half}>
              <label htmlFor="last_name">Surname*</label>
              <input type="text"  name="last_name" id="last_name" onBlur={this.handleInputChange} onChange={this.handleInputChange} />
              {this.state.formErrors.first_name.length > 0 &&
              <span className={styles.contact_form__mobile_error}>{this.state.formErrors.last_name}</span>}
            </div>
          </div>
          <div className={styles.contact_form__error_row}>
            <div className={styles.contact_form__row_half}>
              {this.state.formErrors.first_name.length > 0 &&
              <span className={styles.contact_form__desktop_error}>{this.state.formErrors.first_name}</span>}
            </div>
            <div className={styles.contact_form__row_half}>
              {this.state.formErrors.last_name.length > 0 &&
              <span className={styles.contact_form__desktop_error}>{this.state.formErrors.last_name}</span>}
            </div>
          </div>
          <div className={styles.contact_form__row}>
            <label htmlFor="email_address">Email Address*</label>
            <input type="email" name="email_address" id="email_address" onBlur={this.handleInputChange} onChange={this.handleInputChange} />
            {this.state.formErrors.email_address.length > 0 &&
            <span className={styles.contact_form__desktop_error}>{this.state.formErrors.email_address}</span>}
          </div>
          <div className={styles.contact_form__row}>
            <label htmlFor="company">Company Name</label>
            <input type="text" name="company" id="company" onChange={this.handleInputChange} />
          </div>
          <div className={styles.contact_form__row}>
            <label htmlFor="phone">Phone Number</label>
            <input type="text" name="phone" id="phone" onChange={this.handleInputChange} />
          </div>
          <div className={styles.contact_form__row}>
            <label htmlFor="enquiry">Enquiry*</label>
            <textarea rows="10" name="enquiry" id="enquiry" onBlur={this.handleInputChange} onChange={this.handleInputChange} ></textarea>
            {this.state.formErrors.enquiry.length > 0 &&
            <span className={styles.contact_form__desktop_error}>{this.state.formErrors.enquiry}</span>}
          </div>
          <div className={styles.contact_form__row}>
            <button className={cta.submit} type="submit" value="Submit" name="subscribe" id="subscribe_button" disabled={!validForm}>Submit</button>
          </div>
          {errorMessage}
        </form>
      </div>
    );
  }
}
